export const donorsFetch = (request, opts) => {
  const config = useRuntimeConfig();

  return $fetch(request, {
    lazy: true,
    server: false,
    baseURL:
      config.public.API_HOST +
      "/application/api/a7f36c1ecc6fdf344c02/ecbf8d13ceb52feda095",
    headers: {
      Authorization: useDonorsAuthStore().currentToken()
        ? "Bearer " + useDonorsAuthStore().currentToken()
        : "",
    },
    onResponse({ request, response, options }) {
      if (response.status == 401) {
        window.localStorage.clear();
        window.location.reload();
      }
    },
    ...opts,
  });
};
